function setCookie(cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + (exdays*24*60*60*1000));
    var expires = "expires="+d.toUTCString();
    document.cookie = cname + "=" + cvalue + "; " + expires;
}

function getCookie(cname) {
    var name = cname + "=";
    var ca = document.cookie.split(';');
    for(var i=0; i<ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0)==' ') c = c.substring(1);
        if (c.indexOf(name) == 0) return c.substring(name.length,c.length);
    }
    return "";
}

(function($, viewport){
    $(document).ready(function() {
        var body = $('body');

        $(window).resize(
            viewport.changed(function() {
                body.removeClass('xs').removeClass('sm').removeClass('md').removeClass('lg').addClass(viewport.current());
                setCookie('bootstrapBreakpoint', viewport.current(), 1);
            })
        );

        body.removeClass('xs').removeClass('sm').removeClass('md').removeClass('lg').addClass(viewport.current());
        setCookie('bootstrapBreakpoint', viewport.current(), 1);
    });
})(jQuery, ResponsiveBootstrapToolkit);