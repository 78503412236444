/**
 * If there is a #masonry element, initialize it
 */

$(document).ready(function(){
    if (document.getElementsByClassName('masonry').length > 0) {
        var msnry;

        msnry = new Masonry( '.masonry', {
            itemSelector: '.brick', // use a separate class for itemSelector, other than .col-
            columnWidth: '.brick',
            percentPosition: true
        });

        $( window ).load(function() {
            msnry.layout();
        });
    }
});